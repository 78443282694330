/* @tailwind base; */
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}

p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
label {
  margin: 0;
  @apply font-inter;
}

#root {
  min-height: 100vh;
}

.ant-card {
  @apply border-zinc-300 rounded-sm;
}

.ant-card .ant-card-body {
  @apply rounded-sm;
}

.ant-card .ant-card-head {
  padding: 10px 20px;
  @apply bg-black/[.06];
}

.ant-card .ant-card-head .ant-card-head-title {
  padding: 0;
  @apply text-neutral-800 font-roboto font-normal;
}

.ant-input,
.ant-picker,
.ant-input-affix-wrapper,
.ant-select .ant-select-selector {
  @apply !rounded-sm;
}

.ant-form-item-label label {
  @apply font-medium;
}

.ant-form-item {
  margin-bottom: 24px;
}

.ant-btn {
  @apply shadow-none !important;
  @apply rounded-none !important;
}

.ant-layout .ant-menu {
  background: #172b4d !important;
}

.ant-layout .ant-menu-item {
  height: auto !important;
  line-height: 1.3 !important;
  padding: 0.8rem 0.7rem !important;
  border-radius: 0 !important;
  margin: 0.4rem 0.8rem !important;
  width: calc(100% - 1.6rem) !important;
}

.ant-layout .ant-menu-submenu {
  padding: 0 !important;
}

.ant-layout .ant-menu-submenu .ant-menu-sub {
  margin-left: 1rem;
}

.ant-layout .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-layout .ant-menu-submenu-selected:hover .ant-menu-submenu-title {
  background-color: #2d4b72;
}

.ant-layout .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0 0.7rem !important;
  margin: 0.4rem 0.8rem !important;
  width: calc(100% - 1.6rem) !important;
}

.ant-layout .ant-menu-submenu .ant-menu-item {
  padding: 0.6rem 0.7rem !important;
}

.ant-table {
  background: transparent !important;
}

.ant-table .ant-table-thead .ant-table-cell {
  background-color: #ffffff;
}

.ant-table .ant-table-thead .ant-table-cell::before {
  background-color: transparent !important;
}

.expanded-row-table .ant-table {
  margin: 0 !important;
}

.ant-table .nested-table .ant-table {
  margin: 0 !important;
}
